import { useState, useEffect, useCallback } from "react";
import { CalendarAuthParams } from "src/components/calendar/CalendarModificationScreen";
import { CalendarSourceType } from "src/types/Calendar";

const LOCAL_STORAGE_KEY = "calendarAuthParams";

const useCalendarAuthRedirect = () => {
    // Initialize state from localStorage
    const [calendarAuthParams, setCalendarAuthParams] = useState<CalendarAuthParams | null>(() => {
        const saved = localStorage.getItem(LOCAL_STORAGE_KEY);
        return saved ? JSON.parse(saved) : null;
    });

    useEffect(() => {
        console.log("Initial render - checking URL params");

        const queryParams = new URLSearchParams(window.location.search);
        const success = queryParams.get("calendarAuthSuccess") === "true";

        if (success) {
            const sourceParam = queryParams.get("source");
            const accountParam = queryParams.get("account");

            // Clean up URL after parsing parameters
            queryParams.delete("calendarAuthSuccess");
            queryParams.delete("source");
            queryParams.delete("account");
            window.history.replaceState({}, "", `${window.location.pathname}?${queryParams.toString()}`);

            const source = Object.values(CalendarSourceType).includes(sourceParam as CalendarSourceType)
                ? (sourceParam as CalendarSourceType)
                : undefined;

            if (!source || !accountParam) {
                console.error("Invalid calendar source provided:", sourceParam);
                return;
            }

            const newParams = { source, account: accountParam } as CalendarAuthParams;

            console.log("Setting new calendar auth params:", newParams);

            setCalendarAuthParams(newParams);
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newParams));
        }
    }, []);

    // Keep localStorage in sync when state changes
    useEffect(() => {
        if (calendarAuthParams) {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(calendarAuthParams));
        }
    }, [calendarAuthParams]);

    const clearCalendarAuthParams = useCallback(() => {
        setCalendarAuthParams(null);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    }, []);

    return { calendarAuthParams, clearCalendarAuthParams };
};

export default useCalendarAuthRedirect;
