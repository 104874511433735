import React from "react";
import styles from "./DigestCard.module.css";
import finishedIcon from "../../assets/icons/finishedIcon.svg";

type DigestPlaceholderCardProps = {
    useNoItemsText?: boolean;
};

const DigestPlaceholderCard: React.FC<DigestPlaceholderCardProps> = ({ useNoItemsText }) => {
    const finishedText = "You’re all set! There are no action-required digests at the moment. Check out ‘List View’ to see your upcoming schedules, or keep swiping to start over.";
    const noItemsText = "No action-required digests at the moment. Check out ‘List View’ to see your other upcoming schedules."

    return (
        <div className={styles.section}>
            <div className={styles.placeHolderContentContainer}>
                <div className={styles.finishedIconContainer}>
                    <div className={styles.finishedIconBackground}>
                        <img src={finishedIcon} className={styles.finishedIcon} />
                    </div>
                </div>
                <div className={styles.noMoreCardExplanation}>
                    {useNoItemsText
                        ? noItemsText
                        : finishedText
                    }
                </div>
            </div>
        </div>
    );
};

export default DigestPlaceholderCard;
