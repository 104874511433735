import { getDBConnection } from '../storage/database/calendarDB';
import { Preferences } from '@capacitor/preferences';
import { PreferenceKey } from '../storage/preferences/prefKeys';
import { CalendarPlugin } from 'calendar-plugin';

export interface CalendarSyncResult {
    newEvents: any[];
    updatedEvents: any[];
    removedEvents: any[];
}

export const fetchAndSyncCalendarEvents = async (calendarIDs: string[]): Promise<void> => {
    try {
        // console.log("📆 Starting calendar sync...");

        // const db = await getDBConnection(); // ✅ Get the existing DB connection

        // // ✅ Get last sync timestamp
        // const { value: lastSyncTimestamp } = await Preferences.get({ key: PreferenceKey.LAST_SYNC_TIME });
        const now = new Date();

        const startDate = now.toISOString();
        const endDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000).toISOString();

        // ✅ Fetch events from iOS Calendar
        const eventsResponse = await CalendarPlugin.getEvents({ startDate, endDate, calendarIds: calendarIDs });

        console.log("📆 Fetched events:", eventsResponse.events);

        console.log(eventsResponse);

        // ✅ Fetch previously synced event IDs from SQLite
        // const existingEvents = await db.query("SELECT id, title, startDate, endDate, calendarTitle, lastSynced FROM events");

        // ✅ Convert existing events to a map { id: event }
        // const eventSyncMap = existingEvents?.values?.reduce((map, row) => {
        //     map[row.id] = row;
        //     return map;
        // }, {});

        // // ✅ New and updated events computation
        // const newEvents = [];
        // const updatedEvents = [];

        // for (const event of eventsResponse.events) {
        //     console.log(event);
        //     const existingEvent = eventSyncMap[event.id];

        //     if (!existingEvent) {
        //         // ✅ Event is new
        //         newEvents.push(event);
        //     } else if (
        //         event.startDate !== existingEvent.startDate ||
        //         event.endDate !== existingEvent.endDate
        //     ) {
        //         // ✅ Event is updated
        //         updatedEvents.push(event);
        //     }

        //     // ✅ Remove from the map (we use the remaining ones later for removed events)
        //     delete eventSyncMap[event.id];
        // }

        // // ✅ Remaining events in `eventSyncMap` were previously synced but are now missing → They are removed
        // const removedEvents = Object.values(eventSyncMap);

        // return { newEvents, updatedEvents, removedEvents };
    } catch (error) {
        console.error("❌ Error computing calendar sync changes:", error);
        // return { newEvents: [], updatedEvents: [], removedEvents: [] };
    }
};
