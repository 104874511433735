import { SyncExecutor, SyncStep } from "./SyncExecutor";

export class OnlineSyncExecutor implements SyncExecutor {
    private step: SyncStep = SyncStep.COMPLETED;
    private calendarId: string;
    private isCancelled: boolean = false;
    private onComplete: () => void;

    constructor(calendarId: string, onComplete: () => void) {
        this.calendarId = calendarId;
        this.onComplete = onComplete;
    }


    async startCalendarSync(): Promise<void> {
        console.log("[OnlineSyncExecutor] No-op: startCalendarSync()");
        this.onComplete();
    }

    cancel(): void {
        console.log("[OnlineSyncExecutor] No-op: cancel()");
    }

    getStep(): SyncStep {
        return this.step;
    }
}
