export interface SyncExecutor {
    startCalendarSync(): Promise<void>;
    cancel(): void;
    getStep(): SyncStep;
}

export enum SyncStep {
    STARTING_SYNC = "STARTING_SYNC",
    UPLOADING_CHUNKS = "UPLOADING_CHUNKS",
    CHECKING_SYNC_STATUS = "CHECKING_SYNC_STATUS",
    STARTING_ANALYSIS = "STARTING_ANALYSIS",
    CHECKING_ANALYSIS = "CHECKING_ANALYSIS",
    COMPLETED = "COMPLETED",
}
