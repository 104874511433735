import React, { useState, useEffect } from 'react';
import styles from './CalendarSync.module.css';
import { isIOS, isWeb } from '../../platform/platform';
import { AdditionalButton } from '../onboarding/OnboardingStep';
import googleLogo from '../../assets/google-icon.svg';
import appleLogo from '../../assets/apple-icon-black.svg';
import xButtonIcon from '../../assets/icons/xSmallIcon.svg';
import refreshIcon from '../../assets/icons/refreshIcon.svg';
import ErrorUnit from '../input/ErrorUnit';

export enum AccountProviderType {
    GOOGLE,
    APPLE,
}

export interface SyncedAccount {
    provider: AccountProviderType;
    identifier: string;
    label?: string;
    expired?: boolean;
}

interface CalendarSyncProps {
    existingConnections: SyncedAccount[]; // Prefetched accounts
    onAccountAdd: (provider: AccountProviderType) => Promise<void>; // Handles adding an account
    onAccountRemove: (identifier: string) => Promise<void>; // Handles removing an account
    setAdditionalButtons?: (buttons: AdditionalButton[]) => void; // Create buttons for higher-level components
    loading: boolean; // New prop for loading state
    error?: Error | undefined; // New prop for error state
}

const CalendarSync: React.FC<CalendarSyncProps> = ({
    existingConnections,
    onAccountAdd,
    onAccountRemove,
    setAdditionalButtons,
    loading,
    error = undefined,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const renderIcon = (iconType?: AccountProviderType) => {
        switch (iconType) {
            case AccountProviderType.GOOGLE:
                return <img src={googleLogo} alt="Google Icon" className={styles.icon} />;
            case AccountProviderType.APPLE:
                return <img src={appleLogo} alt="Apple Icon" className={styles.appleIcon} />;
            default:
                return null;
        }
    };

    const handleRefreshAccount = async (provider: AccountProviderType) => {
        await handleAddAccount(provider);
    }

    // Handle adding an account
    const handleAddAccount = async (provider: AccountProviderType) => {
        try {
            setIsLoading(true);
            await onAccountAdd(provider); // Delegates to parent-provided logic
        } catch (error) {
            console.error(`Failed to add ${provider} account:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    // Handle removing an account
    const handleRemoveAccount = async (identifier: string) => {
        try {
            setIsLoading(true);
            await onAccountRemove(identifier); // Delegates to parent-provided logic
        } catch (error) {
            console.error(`Failed to remove account:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    // Dynamically set additional buttons
    useEffect(() => {
        if (setAdditionalButtons) {
            let buttonsList: AdditionalButton[] = [{
                label: 'Add Google Account',
                onClick: () => handleAddAccount(AccountProviderType.GOOGLE),
                type: 'primary',
                iconType: 'Google',
                hasDataLoad: true,
                className: styles.calendarAddButton,
            }];
            if (isIOS()) {
                buttonsList = buttonsList.concat([{
                    label: 'Add Device Calendar',
                    onClick: () => handleAddAccount(AccountProviderType.APPLE),
                    type: 'primary',
                    iconType: 'Apple',
                    hasDataLoad: true,
                    className: styles.calendarAddButton,
                }]);
            }
            setAdditionalButtons(buttonsList);
        }
    }, [setAdditionalButtons]);

    return (
        <>
            <div className={`${styles.calendarSyncContainer} ${!isLoading && existingConnections.length == 0 ? styles.emptyContainer : ''}`}>
                <div className={styles.syncedAccounts}>
                    {isLoading || loading ? (
                        <p>Loading accounts...</p>
                    ) : (
                        existingConnections.map((account, index) => {
                            const isLastRow = index === existingConnections.length - 1;
                            const disabledRow = isWeb() && account.provider !== AccountProviderType.GOOGLE;

                            return (
                                <div
                                    key={index}
                                    className={`${styles.accountRow} ${isLastRow ? styles.lastAccountRow : ""}`}
                                >
                                    <div className={`${styles.regularContainer} ${disabledRow ? styles.disabledContainer : ""}`}>
                                        <div className={styles.leftAccountRowContainer}>
                                            {renderIcon(account.provider)}
                                            <span className={`${styles.accountEmail} ${account.expired ? styles.accountEmailRefreshWidth : styles.accountEmailRegularWidth}`}>
                                                {account.label ? account.label : account.identifier}
                                            </span>
                                        </div>
                                        <div className={styles.rightAccountRowContainer}>
                                            {account.expired && <button
                                                className={styles.refreshButton}
                                                onClick={() => handleRefreshAccount(account.provider)}
                                                disabled={isLoading || disabledRow}
                                            >
                                                <img src={refreshIcon} className={styles.refreshIcon} />
                                                Refresh
                                            </button>}
                                            <button
                                                className={styles.removeButton}
                                                onClick={() => handleRemoveAccount(account.identifier)}
                                                disabled={isLoading || disabledRow}
                                            >
                                                <img src={xButtonIcon} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>

            {!(isLoading || loading) && existingConnections.length == 0 && <ErrorUnit errorStr={"At least one account must be connected."} />}
        </>
    );
};

export default CalendarSync;
