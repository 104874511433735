import React, { createContext, useContext, useEffect, useState } from "react";
import { SyncSourceType, TaskEnvironmentType, TaskStatusType, useGetDeviceRequiredSyncQuery } from "src/gql/types";

interface SyncProviderProps {
    identifier?: string; // Can be undefined on mobile web
    children: React.ReactNode;
}

interface SyncRange {
    start: string;
    end: string;
}

interface SyncStatus {
    status: TaskStatusType; // TaskStatusType (e.g., "NEEDS_SYNC", "COMPLETED", etc.)
    syncRange: SyncRange | null; // Optional sync range
    lastCompletedAt: string;
    source: SyncSourceType; // SyncSourceType (e.g., "ONLINE", "DEVICE")
}

// SyncStatus mapping per calendarID
type SyncStatusMap = Record<string, SyncStatus>;

interface SyncContextType {
    checkSyncStatus: boolean;
    setCheckSyncStatus: React.Dispatch<React.SetStateAction<boolean>>;
    syncStatus: SyncStatusMap;
    setSyncStatus: React.Dispatch<React.SetStateAction<SyncStatusMap>>;
    isDeviceSyncDisabled: boolean;
    onSyncCompleted?: (calendarId: string) => void;
}

const defaultSetSyncStatus: React.Dispatch<React.SetStateAction<SyncStatusMap>> = () => { };
const defaultSetCheckSyncStatus: React.Dispatch<React.SetStateAction<boolean>> = () => { };

const SyncStatusContext = createContext<SyncContextType>({
    checkSyncStatus: false,
    setCheckSyncStatus: defaultSetCheckSyncStatus,
    syncStatus: {},
    setSyncStatus: defaultSetSyncStatus,
    isDeviceSyncDisabled: true,
    onSyncCompleted: undefined,
});

export const SyncStatusProvider: React.FC<SyncProviderProps> = ({ children, identifier }) => {
    const [checkSyncStatus, setCheckSyncStatus] = useState(false);
    const [syncStatus, setSyncStatus] = useState<SyncStatusMap>({});

    // If identifier is missing, device sync is disabled
    const isDeviceSyncDisabled = !identifier;

    // TODO: change this to accept the returned sync status object from the server
    const onSyncCompleted = (calendarId: string) => {
        setSyncStatus((prev) => ({
            ...prev,
            [calendarId]: { ...prev[calendarId], status: TaskStatusType.Completed },
        }));
    };

    // Skip query if identifier is unavailable
    const { data } = useGetDeviceRequiredSyncQuery({
        variables: { identifier: identifier ?? "", taskEnvironment: TaskEnvironmentType.Foreground },
        fetchPolicy: "network-only",
        skip: isDeviceSyncDisabled, // Prevents the query from running
    });

    useEffect(() => {
        if (!isDeviceSyncDisabled && data?.getDeviceRequiredSync) {
            // Convert array to object for quick lookup
            const syncMap: SyncStatusMap = data.getDeviceRequiredSync.reduce((acc, item) => {
                if (item === null) {
                    return {};
                }
                acc[item.calendarID] = {
                    status: item.status,
                    syncRange: item.syncRange || null,
                    lastCompletedAt: item.lastCompletedAt,
                    source: item.source,
                };
                return acc;
            }, {} as SyncStatusMap);
            setSyncStatus(syncMap);
        }
    }, [data, isDeviceSyncDisabled]);

    return (
        <SyncStatusContext.Provider value={{
            checkSyncStatus,
            setCheckSyncStatus,
            syncStatus,
            setSyncStatus,
            isDeviceSyncDisabled,
            onSyncCompleted
        }}>
            {children}
        </SyncStatusContext.Provider>
    );
};

export const useSyncStatus = () => useContext(SyncStatusContext);
