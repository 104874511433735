import React, { useState, useEffect } from 'react';
import { CalendarConnection, ExternalSource, useGetCalendarConnectionsLazyQuery } from '../../gql/types';
import { isIOS } from 'src/platform/platform';
import { appEvents } from 'src/App';
import { AppEventType } from 'src/events/app';
import { CalendarSourceType } from 'src/types/Calendar';
import CalendarListSelectionView from './CalendarListSelectionView';
import CalendarRefreshView from './CalendarRefreshView';
import CalendarSyncView from './CalendarSyncView';
import { useSyncStatus } from 'src/contexts/SyncStatusProvider';

export interface CalendarAuthParams {
    source: CalendarSourceType;
    account: string;
    label?: string;
}

interface CalendarModificationScreenProps {
    prefetchedConnections?: CalendarConnection[] | null;
    calendarAuthParams?: CalendarAuthParams | null;
    clearCalendarAuthParams?: () => void;
    isBlocking?: boolean,
    onContinue: () => void;
}

const CalendarModificationScreen: React.FC<CalendarModificationScreenProps> = ({
    prefetchedConnections,
    calendarAuthParams,
    clearCalendarAuthParams,
    isBlocking,
    onContinue,
}) => {
    const { setCheckSyncStatus } = useSyncStatus();
    const [connections, setConnections] = useState<CalendarConnection[] | null>(
        prefetchedConnections ?? null
    );
    const [showRefreshView, setShowRefreshView] = useState(false);
    const [showSyncView, setShowSyncView] = useState(false);
    const [authParams, setAuthParams] = useState<CalendarAuthParams | null>(calendarAuthParams ?? null);

    // Use Apollo Client's useLazyQuery to fetch calendar connections
    const [getCalendarConnections, { loading, data }] = useGetCalendarConnectionsLazyQuery({
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (data?.getCalendarConnections) {
            // TODO: improve the change check, but good enough for now.
            if (connections !== null && data.getCalendarConnections.length !== connections.length) {
                setCheckSyncStatus(true);
            }
            setConnections(data.getCalendarConnections);
        }
    }, [setConnections, setCheckSyncStatus, data]);

    useEffect(() => {
        if (calendarAuthParams && clearCalendarAuthParams) {
            // tell the app to check sync status since there may have been a new connection/modification
            // this will get triggered in web
            clearCalendarAuthParams();
        }
    }, [calendarAuthParams, clearCalendarAuthParams]);

    // Trigger an initial fetch if no prefetched connections are available
    useEffect(() => {
        if (!prefetchedConnections || prefetchedConnections.length === 0) {
            getCalendarConnections(); // Manually trigger query
        }
    }, [getCalendarConnections, prefetchedConnections]);

    // Check to see if we redirected from OAuth. This will get triggered in app. 
    useEffect(() => {
        const listener = (eventParams: { source: CalendarSourceType; account: string; label: string }) => {
            setAuthParams(eventParams);
            setShowSyncView(false);
            setShowRefreshView(false);
        };

        appEvents.on(AppEventType.CALENDAR_UPDATED, listener);
        return () => {
            appEvents.off(AppEventType.CALENDAR_UPDATED, listener);
        };
    }, []);

    // Handle stale connections & determine blocking behavior
    useEffect(() => {
        // connections might not have been loaded yet, so in that case use local empty array
        const conns = connections == null ? [] : connections;
        if (authParams !== null) {
            setShowRefreshView(false);
            setShowSyncView(false);
            return;
        }

        const staleConnections = conns.filter((connection) => {
            if (connection.source === ExternalSource.Google) {
                return connection.expired;
            }
            return isIOS() && connection.source === ExternalSource.Apple ? connection.expired : false;
        });
        if (staleConnections.length > 0) {
            setShowRefreshView(true);
            setShowSyncView(false);
            return;
        } else {
            if (isBlocking) {
                const nonStaleAccounts = conns.filter((connection) => {
                    return !connection.expired;
                });
                if (nonStaleAccounts.length === 0) {
                    setShowSyncView(true);
                    setShowRefreshView(false);
                    return;
                }
            } else {
                setShowSyncView(true);
                setShowRefreshView(false);
            }
        }
        if (isBlocking) {
            onContinue();
        }
    }, [connections, authParams, onContinue]);

    // Callback to refetch & update UI after actions
    const onCalendarListSelect = async () => {
        setAuthParams(null);
        // Universal location (web and app) to check for sync status, since the user would have selected calendars.
        setCheckSyncStatus(true);
        await getCalendarConnections();
    };

    const onCalendarRefresh = async () => {
        await getCalendarConnections();
    };

    const onSync = async () => {
        onContinue();
    };

    return (
        <>
            {authParams && (
                <CalendarListSelectionView
                    source={authParams.source}
                    account={authParams.account}
                    label={authParams.label}
                    onNext={onCalendarListSelect}
                />
            )}
            {showRefreshView && (
                <CalendarRefreshView connections={connections !== null ? connections : []} onContinue={onCalendarRefresh} />
            )}
            {showSyncView && <CalendarSyncView connections={connections !== null ? connections : []} onContinue={onSync} dataLoading={loading} />}
        </>
    );
};

export default CalendarModificationScreen;
