import { SyncExecutor } from "./SyncExecutor";
import { OnlineSyncExecutor } from "./OnlineSyncExecutor";
import { DeviceSyncExecutor } from "./DeviceSyncExecutor";
import { SyncSourceType } from "src/gql/types";

export const SyncManager = {
    createSyncExecutor: (calendarId: string, source: SyncSourceType, onSyncFinished: () => void): SyncExecutor => {
        return source === SyncSourceType.Device
            ? new DeviceSyncExecutor(calendarId, onSyncFinished)
            : new OnlineSyncExecutor(calendarId, onSyncFinished);
    }
};
