import React, { useState, useEffect } from 'react';
import CalendarSync, { AccountProviderType, SyncedAccount } from '../profile/CalendarSync';
import { useNavigate } from 'react-router-dom';
import { AUTH_TYPE, handleLoginWithGoogle } from '../../google/loginHandler';
import { appEvents } from '../../App';
import { AppEventType } from '../../events/app';
import { CalendarPlugin } from 'calendar-plugin';
import { CalendarSourceType } from '../../types/Calendar';
import { CalendarConnection, ExternalSource, useRemoveCalendarConnectionMutation } from 'src/gql/types';
import ActionButton from '../button/ActionButton';
import Layout from '../layout/Layout';
import { AdditionalButton } from '../onboarding/OnboardingStep';
import SecondaryButton from '../button/SecondaryButton';
import styles from './CalendarComponents.module.css';

interface CalendarSyncViewProps {
    connections: CalendarConnection[];
    onBack?: () => void;
    onContinue: () => void;
    dataLoading?: boolean;
}

const CalendarSyncView: React.FC<CalendarSyncViewProps> = ({
    connections,
    onBack,
    onContinue,
    dataLoading,
}) => {
    const [syncedAccounts, setSyncedAccounts] = useState<SyncedAccount[]>([]);

    // ✅ Ensure syncedAccounts updates when connections change
    useEffect(() => {
        setSyncedAccounts(
            connections.map((connection: CalendarConnection) => ({
                provider: connection.source === 'Google' ? AccountProviderType.GOOGLE : AccountProviderType.APPLE,
                identifier: connection.identifier,
                expired: connection.expired,
                label: connection.label ?? undefined,
            }))
        );
    }, [connections]); // Runs whenever `connections` changes


    const [buttonEnabled, setButtonEnabled] = useState(false); // Tracks button state
    const [additionalButtons, setAdditionalButtons] = useState<
        AdditionalButton[]
    >([]);
    const navigate = useNavigate();

    const [removeCalendarConnection] = useRemoveCalendarConnectionMutation();

    // Update button state whenever syncedAccounts changes
    useEffect(() => {
        setButtonEnabled(syncedAccounts.filter((account) => !account.expired).length > 0); // Enable button only when accounts exist
    }, [syncedAccounts]);

    // Handle adding an account
    const handleAddAccount = async (provider: AccountProviderType) => {
        if (provider == AccountProviderType.GOOGLE) {
            await handleLoginWithGoogle(AUTH_TYPE.CALENDAR, "/user/profile/");
        } else if (provider === AccountProviderType.APPLE) {
            try {
                const response = await CalendarPlugin.requestCalendarPermissions();
                if (response.granted) {
                    appEvents.emit(AppEventType.CALENDAR_UPDATED, { source: CalendarSourceType.APPLE, account: response.deviceIdentifier, label: response.deviceName });
                } else {
                    console.error('Calendar permissions not granted.');
                }
            } catch (error) {
                console.error('Error requesting calendar permissions:', error);
            }
        }
    };

    // Handle removing an account
    const handleRemoveAccount = async (identifier: string) => {
        try {
            const accountToRemove = syncedAccounts.find(
                (account) => account.identifier === identifier
            );
            if (!accountToRemove) {
                console.error(`Account with email ${identifier} not found`);
                return;
            }

            let source: ExternalSource;
            switch (accountToRemove.provider) {
                case AccountProviderType.GOOGLE:
                    source = ExternalSource.Google;
                    break;
                case AccountProviderType.APPLE:
                    source = ExternalSource.Apple;
                    break;
                default:
                    console.error("invalid source: ", accountToRemove.provider);
                    return;
            }

            // Execute the mutation
            await removeCalendarConnection({
                variables: {
                    calendar: {
                        source: source,
                        identifier: accountToRemove.identifier,
                    },
                },
            });

            // Update local state after successful mutation
            setSyncedAccounts((prev) =>
                prev.filter((account) => account.identifier !== identifier)
            );
        } catch (error) {
            console.log(error);
            navigate(`?error=true&message=${encodeURIComponent('There was an error removing the account.')}`);
        }
    };

    const handleContinue = () => {
        onContinue();
    };

    const additionalButtonElements = additionalButtons.map((button, index) =>
        button.type === 'primary' ? (
            <ActionButton
                key={index}
                onClick={button.onClick}
                iconType={button.iconType}
                hasDataLoad={button.hasDataLoad}
                className={button.className}
            >
                {button.label}
            </ActionButton>
        ) : (
            <SecondaryButton key={index} onClick={button.onClick}>
                {button.label}
            </SecondaryButton>
        )
    );

    return (
        <Layout
            navbarConfig={{ title: "Calendar Account", onBack: onBack }}
            footer={
                <div className={styles.buttonContainer}>
                    {additionalButtonElements}
                    <ActionButton
                        onClick={handleContinue}
                        disabled={!buttonEnabled}
                        hasDataLoad={false}
                    >
                        Confirm
                    </ActionButton>
                </div>
            }
        >
            <div className={styles.instructions}>
                {syncedAccounts.length > 0
                    ? <>
                        Confirm your synced calendars to proceed, or add more if needed.
                    </>
                    : <>
                        Please add at least one caledar so we can make accurate recommendations!
                    </>}
            </div>
            <CalendarSync
                existingConnections={syncedAccounts} // Prefetched accounts
                onAccountAdd={handleAddAccount} // Pass handleAddAccount directly
                onAccountRemove={handleRemoveAccount} // Pass handleRemoveAccount directly
                setAdditionalButtons={setAdditionalButtons} // Pass down callback to set buttons
                loading={dataLoading ?? false}
            />
        </Layout>
    );
};

export default CalendarSyncView;
