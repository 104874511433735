import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import DigestStackedView from './DigestStackedView';
import { Preferences } from "@capacitor/preferences";
import { Digest, useGenerateDigestLazyQuery, User } from '../../gql/types';
import { DigestViewFooter } from './DigestViewFooter';
import styles from './DigestView.module.css'; // Add custom styles if needed
import DigestListView from './DigestListView';
import { DigestViewProvider } from './DigestViewContext';
import DigestViewActionSheetWrapper from './DigestViewActionSheetWrapper';
import DigestViewCaretakerRequestWrapper from './DigestViewCaretakerRequestCreateViewWrapper';
import DigestViewCaretakerRequestConfirmationViewWrapper from './DigestViewCaretakerRequestConfirmationViewWrapper';
import { useSearchParams } from 'react-router-dom';
import { motion } from "framer-motion";
import DataSetupComponent from '../setup/DataSetupComponent';
import { PreferenceKey } from 'src/storage/preferences/prefKeys';
import Spinner from '../button/Spinner';
import { useSyncStatus } from 'src/contexts/SyncStatusProvider';
import { LOCAL_MODE } from 'src/config/local';

const CACHE_EXPIRATION_MS = 60 * 60 * 1000; // 1 hour in milliseconds

interface DigestViewProps {
    user: User;
}

const DigestView: React.FC<DigestViewProps> = ({ user }) => {
    const [isListView, setIsListView] = useState(false);
    const [isDigestVisible, setIsDigestVisible] = useState(true);
    const [currentDigest, setCurrentDigest] = useState<Digest | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showDataLoadingScreen, setShowDataLoadingScreen] = useState(false);
    const [shouldClearSearchParams, setShouldClearSearchParams] = useState(false);
    const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
    const { checkSyncStatus, setCheckSyncStatus } = useSyncStatus();

    // Lazy query to fetch digest manually
    const [fetchDigest, { loading }] = useGenerateDigestLazyQuery({
        onCompleted: async (data) => {
            if (data?.generateDigest) {
                setShowDataLoadingScreen(false);
                setShouldClearSearchParams(true);
                setCurrentDigest(data.generateDigest);
                if (!LOCAL_MODE) {
                    await Preferences.set({ key: PreferenceKey.DIGEST, value: JSON.stringify(data.generateDigest) });
                    await Preferences.set({ key: PreferenceKey.DIGEST_FETCHED_AT, value: Date.now().toString() });
                }
            }
        },
        onError: (err) => {
            console.error("Error fetching digest:", err);
        },
    });

    const checkLastSyncWithinWindow = async () => {
        const lastSynced = await Preferences.get({ key: PreferenceKey.LAST_SYNC_TIME });

        if (lastSynced.value) {
            const timeSinceLastSync = Date.now() - parseInt(lastSynced.value, 10);
            if (timeSinceLastSync < CACHE_EXPIRATION_MS) {
                return true;
            }
        }

        return false;
    };

    useEffect(() => {
        const loadDigest = async () => {
            const cachedDigest = await Preferences.get({ key: PreferenceKey.DIGEST });
            const lastFetched = await Preferences.get({ key: PreferenceKey.DIGEST_FETCHED_AT });
            const lastSyncWithinCacheWindow = await checkLastSyncWithinWindow();

            if (searchParams.has("refetch")) {
                if (lastSyncWithinCacheWindow) {
                    fetchDigest();
                    return;
                }
                setShowDataLoadingScreen(true);
                return;
            }

            if (showDataLoadingScreen) {
                return;
            }

            // Load from cache if still valid
            if (cachedDigest.value && lastFetched.value) {
                const timeSinceLastFetch = Date.now() - parseInt(lastFetched.value, 10);
                if (timeSinceLastFetch < CACHE_EXPIRATION_MS && lastSyncWithinCacheWindow) {
                    setCurrentDigest(JSON.parse(cachedDigest.value));
                    return;
                }
            }

            // If sync is within cache window, fetch new digest
            if (lastSyncWithinCacheWindow) {
                fetchDigest();
                return;
            }

            // If no valid cache & sync is outside window, show loading
            setShowDataLoadingScreen(true);
        };

        loadDigest();

    }, [searchParams]); // ✅ Combined logic

    useEffect(() => {
        if (shouldClearSearchParams) {
            setSearchParams({});
            setShouldClearSearchParams(false); // Reset flag
        }
    }, [setSearchParams, shouldClearSearchParams]);

    useEffect(() => {
        console.log("in check sync status use effect");
        if (checkSyncStatus) {
            console.log("in check sync status use effect: ", checkSyncStatus);
            setShowDataLoadingScreen(true);
            setCheckSyncStatus(false); // Reset to prevent loops
        }
    }, [setCheckSyncStatus, checkSyncStatus]);

    const dataLoadingCallback = () => {
        fetchDigest();
    }

    // State to signal the "prev card" action
    const [triggerPrevSignal, setTriggerPrevSignal] = useState(0);

    // Toggle between list and stacked views
    const handleSwitchView = () => {
        setIsListView((prev) => !prev);
    };

    // Handler for "Prev" button, passed down to DigestStackedView
    const handlePrevCard = () => {
        setTriggerPrevSignal((prev) => prev + 1); // Increment signal value
    };

    return (
        <DigestViewProvider setIsDigestVisible={setIsDigestVisible}>
            <Layout
                navbarConfig={{ title: "", hamburgerMenu: true, showLogo: true }}
                //                overflow="hidden" // Prevent overflow for the carousel
                isHidden={!isDigestVisible}
                footer={(
                    <DigestViewFooter
                        enablePrevButton={prevButtonEnabled}
                        onPrevCard={handlePrevCard}
                        onSwitchView={handleSwitchView}
                        isListView={isListView}
                        loading={loading}
                    />
                )}
                user={user}
            >
                {showDataLoadingScreen
                    ? <DataSetupComponent
                        user={user}
                        onComplete={dataLoadingCallback}
                        displayLogo={false}
                        className={styles.loadingScreen}
                        initialMessage={"Checking your schedule..."} />
                    : (loading
                        ? <div className={styles.digestLoadingScreen}>
                            <div className={styles.spinnerContainer}>
                                <Spinner size={36} className={styles.spinner} />
                            </div>
                        </div>
                        : <motion.div
                            className={styles.digestContainer}
                            initial={{ opacity: 0, scale: 0.98 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: "easeInOut" }}
                        >
                            {isListView ? (
                                <DigestListView digest={currentDigest} />
                            ) : (
                                <DigestStackedView
                                    setEnablePrevButton={setPrevButtonEnabled}
                                    digest={currentDigest}
                                    triggerPrevSignal={triggerPrevSignal} // Pass signal to DigestStackedView
                                />
                            )
                            }
                            <DigestViewActionSheetWrapper />
                        </motion.div>)}
            </Layout>
            <DigestViewCaretakerRequestWrapper user={user} />
            <DigestViewCaretakerRequestConfirmationViewWrapper user={user} digest={currentDigest} setDigest={setCurrentDigest} />
        </DigestViewProvider>
    );
};

export default DigestView;
