import { SyncExecutor, SyncStep } from "./SyncExecutor";

export class DeviceSyncExecutor implements SyncExecutor {
    private calendarId: string;
    private step: SyncStep = SyncStep.STARTING_SYNC;
    private isCancelled: boolean = false;
    private chunkUploads: number = 0;
    private onComplete: () => void;

    constructor(calendarId: string, onComplete: () => void) {
        this.calendarId = calendarId;
        this.onComplete = onComplete;
    }

    async startCalendarSync() {
        if (this.isCancelled) return;
        this.step = SyncStep.STARTING_SYNC;
        console.log(`Starting device sync for ${this.calendarId}`);

        try {
            // await startCalendarSyncMutation({ variables: { calendarId: this.calendarId } });
            this.step = SyncStep.UPLOADING_CHUNKS;
            this.uploadCalendarChunks();
        } catch (error) {
            console.error(`Device sync failed for ${this.calendarId}`, error);
            this.onComplete();
        }
    }

    async uploadCalendarChunks() {
        if (this.isCancelled) return;
        console.log(`Uploading chunks for ${this.calendarId}`);

        for (let i = 0; i < 5; i++) {
            if (this.isCancelled) return;
            // await uploadChunkMutation({ variables: { calendarId: this.calendarId, chunkIndex: i } });
            this.chunkUploads++;
            console.log(`Chunk ${i} uploaded for ${this.calendarId}`);
        }

        this.step = SyncStep.STARTING_ANALYSIS;
        this.startCalendarAnalysis();
    }

    async startCalendarAnalysis() {
        if (this.isCancelled) return;
        this.step = SyncStep.STARTING_ANALYSIS;

        try {
            // await startCalendarAnalysisMutation({ variables: { calendarId: this.calendarId } });
            this.step = SyncStep.CHECKING_ANALYSIS;
            this.checkCalendarAnalysisStatus();
        } catch (error) {
            console.error(`Analysis start failed for ${this.calendarId}`, error);
            this.onComplete();
        }
    }

    async checkCalendarAnalysisStatus() {
        if (this.isCancelled) return;

        try {
            // const { data } = await checkAnalysisProgress({ variables: { calendarId: this.calendarId } });

            // if (data?.analysisCompleted) {
            console.log(`Analysis completed for ${this.calendarId}`);
            this.step = SyncStep.COMPLETED;
            this.onComplete();
            // } else {
            //     setTimeout(() => this.checkCalendarAnalysisStatus(), 3000);
            // }
        } catch (error) {
            console.error(`Polling failed for analysis ${this.calendarId}`, error);
            this.onComplete();
        }
    }

    cancel() {
        console.log(`Cancelling device sync for ${this.calendarId}`);
        this.isCancelled = true;
    }

    getStep(): SyncStep {
        return this.step;
    }

    getChunkProgress(): number {
        return this.chunkUploads;
    }
}
