import { gql } from '@apollo/client';

export const GET_CALENDAR_CONNECTIONS = gql`
  query GetCalendarConnections {
    getCalendarConnections {
      source
      identifier
      expired
      label
      calendars {
        id
        name
        type
      }
    }
  }
`;
