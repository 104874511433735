import { useEffect, useState } from "react";
import { SyncManager } from "./SyncManager";
import { useSyncStatus } from "../contexts/SyncStatusProvider"; // ✅ Now useSync() works
import { SyncExecutor, SyncStep } from "./SyncExecutor";
import { SyncSourceType, TaskStatusType } from "src/gql/types";
import { DeviceSyncExecutor } from "./DeviceSyncExecutor";

interface SyncProgress {
    syncStep: SyncStep;
    chunkProgress?: number;
}

const SyncHandler = () => {
    const { syncStatus, onSyncCompleted } = useSyncStatus();
    const [syncHandles, setSyncHandles] = useState<Map<string, SyncExecutor>>(new Map());
    const [lastProgressMap, setLastProgressMap] = useState<Map<string, SyncProgress>>(new Map());

    const setSyncProgress = (calendarId: string, executor: SyncExecutor) => {
        setLastProgressMap((prev) => {
            const newProgress = new Map(prev);
            const currentStep = executor.getStep();

            const newSyncProgress: SyncProgress = { syncStep: currentStep };

            if (executor instanceof DeviceSyncExecutor && currentStep === SyncStep.UPLOADING_CHUNKS) {
                newSyncProgress.chunkProgress = executor.getChunkProgress();
            }

            newProgress.set(calendarId, newSyncProgress);
            return newProgress;
        });
    };

    const addActiveSync = (calendarId: string, source: SyncSourceType) => {
        setSyncHandles((prev) => {
            if (prev.has(calendarId)) return prev;
            const executor = SyncManager.createSyncExecutor(calendarId, source, () => removeActiveSync(calendarId));
            executor.startCalendarSync();

            const newHandles = new Map(prev);
            newHandles.set(calendarId, executor);

            setSyncProgress(calendarId, executor);

            return newHandles;
        });

        // ✅ Auto-cancel if stuck in the same step for too long
        setTimeout(() => {
            if (!syncHandles.has(calendarId)) {
                return;
            }

            const executor = syncHandles.get(calendarId);
            if (!executor) return;

            const lastProgress = lastProgressMap.get(calendarId);

            if (!lastProgress) {
                setSyncProgress(calendarId, executor);
                return;
            }

            const currentStep = executor.getStep();
            if (executor instanceof DeviceSyncExecutor) {
                if (
                    lastProgress.syncStep === SyncStep.UPLOADING_CHUNKS &&
                    currentStep === SyncStep.UPLOADING_CHUNKS &&
                    executor.getChunkProgress() > (lastProgress.chunkProgress ?? 0)
                ) {
                    console.log(`Chunk progress detected, not cancelling ${calendarId}`);
                    setSyncProgress(calendarId, executor); // ✅ Update progress
                    return;
                }
            }

            if (lastProgress.syncStep !== currentStep) {
                console.log(`Progress detected, not cancelling ${calendarId}`);
                setSyncProgress(calendarId, executor); // ✅ Update progress
                return;
            }
            console.log(`Sync for ${calendarId} stuck! Cancelling.`);
            executor.cancel();
            removeActiveSync(calendarId);
        }, 60000);
    };

    const removeActiveSync = (calendarId: string) => {
        setSyncHandles((prev) => {
            const newHandles = new Map(prev);
            const currentSize = newHandles.size;
            newHandles.delete(calendarId);
            if (currentSize > 0 && newHandles.size === 0 && onSyncCompleted) {
                onSyncCompleted(calendarId);
            }
            return newHandles;
        });

        setLastProgressMap((prev) => {
            const newProgress = new Map(prev);
            newProgress.delete(calendarId);
            return newProgress;
        });
    };

    useEffect(() => {
        Object.entries(syncStatus).forEach(([calendarId, { status, source }]) => {
            if (status === TaskStatusType.NeedsSync && !syncHandles.has(calendarId)) {
                addActiveSync(calendarId, source);
            }
        });
    }, [syncStatus]);

    return null;
};

export default SyncHandler;
