import React from "react";
import styles from "./DigestHeader.module.css"; // New CSS module for the header
import hornIcon from "../../assets/icons/horn.svg";
import familyIcon from "../../assets/icons/familyIcon.svg";
import birthdayIcon from "../../assets/icons/birthdayIcon.svg";
import { AttentionLevelType, CalendarReminderType } from "../../gql/types";

type DigestHeaderProps = {
    topTitle: string;
    mainTitle: string;
    attentionLevel: AttentionLevelType;
    description?: string;
    mainReminderType?: CalendarReminderType;
};

const DigestHeader: React.FC<DigestHeaderProps> = ({ topTitle, mainTitle, attentionLevel, description, mainReminderType }) => {
    let headerStyle: string = "";

    let headerIconSrc = hornIcon;
    switch (attentionLevel) {
        case AttentionLevelType.ActionRequired:
        case AttentionLevelType.MayNeedAction:
            headerIconSrc = hornIcon;
            headerStyle = styles.highAttentionLevel;
            break;
        case AttentionLevelType.FamilyEvent:
            headerIconSrc = familyIcon;
            headerStyle = styles.familyEventHeader;
            break;
        case AttentionLevelType.Reminder:
            if (mainReminderType == CalendarReminderType.Birthday) {
                headerIconSrc = birthdayIcon;
                headerStyle = styles.birthdayEventHeader;
            } else {
                headerStyle = styles.reminderHeader;
            }
            break;
        case AttentionLevelType.Informational:
            break;
    }

    return (
        <div className={styles.itemHeaderWrapper}>
            <div className={`${styles.itemHeader} ${headerStyle}`}>
                <div className={styles.leftHeader}>
                    <div className={styles.topTitle}>{topTitle}</div>
                    <div className={styles.mainTitle}>{mainTitle}</div>
                </div>

                <div className={styles.headerDivider}></div>

                <div className={styles.rightHeader}>
                    {attentionLevel !== AttentionLevelType.Informational &&
                        <div className={styles.attentionHeader} >
                            <div className={styles.hornIconContainer} >
                                <img className={styles.hornIcon} src={headerIconSrc} />
                            </div>
                        </div>}
                    {description && <div className={styles.headerSummary}>
                        {description.split("!").map((part, index) => (
                            <p key={`${index}`}>
                                {part.trim() /* Remove any extra whitespace */}
                                {description && index < description.split("!").length - 1 && "!" /* Add the "!" back */}
                            </p>
                        ))}
                        {/* {item.itemHeader.description.map((desc, index) => (
                            <p key={index}>{desc}</p> // Render each string inside a paragraph
                        ))} */}
                    </div>}
                    {/* <div className={styles.headerSummary}>{item.itemHeader.description}</div> */}
                </div>
            </div>
        </div>
    );
};

export default DigestHeader;
