import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Route, Routes, Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import Login from './components/login/Login';
import ExistingLoginScreen from './components/login/ExistingLoginScreen';
// @ts-ignore will migrate later
import TermsAndConditions from './components/TermsAndConditions';
// @ts-ignore: have not migrated yet
import PrivacyPolicy from './components/PrivacyPolicy';
// @ts-ignore: have not migrated yet
import JoinBeta from './components/JoinBeta';
import { ToastContainer, toast } from 'react-toastify';
import LandingPage from './components/LandingPage';
import 'react-toastify/dist/ReactToastify.css';
import { isWeb, isNative, isMobileWeb } from './platform/platform';
import './App.css';
import { isAppIntroductionComplete, setAppIntroductionComplete } from './introduction/preferences';
// import AppIntroductionManager from './components/introduction/AppIntroductionManager';
import { SplashScreen as CapacitorSplashScreen } from '@capacitor/splash-screen';
import SplashScreen from './components/introduction/SplashScreen';
import { App as CapacitorApp } from '@capacitor/app';
import { GOOGLE_CONFIG as IOS_GOOGLE_CONFIG } from './config/ios';
import { Browser } from '@capacitor/browser';
import { exchangeCodeForTokens } from './auth/exchange';
import { fetchVerifier } from './auth/pkce';
import { exchangeGoogleTokensForAccesTokens } from './auth/verify';
import { AUTH_TYPE } from './google/loginHandler';
import OnboardingFlow from './components/onboarding/OnboardingFlow';
import { checkAuthentication } from './auth/jwt';
import { LOCAL_MODE, SKIP_ONBOARDING, SHOW_DIGEST_FLOW, SHOW_DATA_LOADING_SCREEN, SHOW_SPLASH_SCREEN, SHOW_LOGIN_SCREEN } from './config/local';
import { CalendarSourceType } from './types/Calendar';
import { EventEmitter } from 'events';
import { AppEventType } from './events/app';
import { Keyboard } from "@capacitor/keyboard";
import DigestView from './components/digest/DigestView';
import { useGetUserLazyQuery, Digest, User, CalendarConnection, SyncType, useGetSyncStatusLazyQuery, TaskStatusType } from './gql/types';
import DataSetupComponent from './components/setup/DataSetupComponent';
import CaretakerRequestRecipientPage from './pages/CaretakerRequestRecipientPage';
import { generateMockUser } from './mock/getUser';
import SignUpScreen from './components/login/SignUpScreen';
import VerifyEmailScreen from './components/login/VerifyEmailScreen';
import EmailVerified from './components/login/EmailVerified';
import ForgotPasswordScreen from './components/login/ForgotPasswordScreen';
import CheckInboxScreen from './components/login/CheckInboxScreen';
import ActionRedirectScreen from './components/login/ActionRedirectScreen';
import ResetPasswordScreen from './components/login/ResetPasswordScreen';
import { SyncStatusProvider } from "./contexts/SyncStatusProvider";
import { PluginListenerHandle } from '@capacitor/core';
import useCalendarRefreshBlockingCondition from './hooks/useCalendarRefreshBlockingCondition';
import CalendarModificationScreen from './components/calendar/CalendarModificationScreen';
import ProfileView from './components/profile/ProfileView';
import { CalendarPlugin } from 'calendar-plugin';
import SyncHandler from './sync/SyncHandler';

export const appEvents = new EventEmitter();

// ProtectedRoute Component
function ProtectedRoute({
  isAuthed,
  user,
  isWeb,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  needsIntro,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  completeIntroductionFunc,
  isCalendarBlocked,
  fetchedConnections,
  calendarBlockRemove,
  onCompleteIntialDataLoad,
  needInitialSync,
  children
}: {
  isAuthed: boolean | null;
  user: User | null;
  isWeb: boolean | null;
  needsIntro: boolean | null;
  completeIntroductionFunc: () => Promise<void>,
  isCalendarBlocked: boolean | null;
  fetchedConnections: CalendarConnection[] | null;
  calendarBlockRemove: () => void;
  onCompleteIntialDataLoad: () => void;
  needInitialSync: boolean,
  children?: React.ReactNode;
}) {
  if (SHOW_DIGEST_FLOW) {
    return <DigestView user={generateMockUser()} />
  }

  if (SHOW_LOGIN_SCREEN) {
    return <Navigate to="/auth/login" replace />;
  }

  // if (needsIntro) {
  //   return <AppIntroductionManager completeIntroduction={completeIntroductionFunc} />; // does not get retriggers
  // }

  if (!SKIP_ONBOARDING && (user && !user.onboardingStatus?.completed)) {
    return <OnboardingFlow user={user} />;
  }

  if (!isAuthed || !user) {
    if (isWeb) {
      return <LandingPage />;
    }
    return <Navigate to="/auth/login" replace />;
  }

  if (isCalendarBlocked) {
    return <CalendarModificationScreen
      prefetchedConnections={fetchedConnections}
      onContinue={calendarBlockRemove}
      isBlocking={true}
    />;
  }

  if (SHOW_DATA_LOADING_SCREEN || (needInitialSync && user?.onboardingStatus?.completed)) {
    return <DataSetupComponent user={user} onComplete={onCompleteIntialDataLoad} />;
  }

  return <>{children}</>;
}

function App(): JSX.Element {
  const [appInitialized, setAppInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(LOCAL_MODE ? true : null);
  const [isIntroductionDone, setIsIntroductionDone] = useState<boolean>(false);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [verifying, setVerifying] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [deviceIdentifier, setDeviceIdentifier] = useState<string | undefined>();
  const [loadingDeviceIdentifier, setLoadingDeviceIdentifier] = useState(isNative()); // Only loading on native

  const location = useLocation();

  const { isBlocked, fetchedConnections, refetch: refetchCalendar } = useCalendarRefreshBlockingCondition(user?.onboardingStatus?.completed ?? false);
  const isCaretakerRequestRoute = location.pathname.startsWith("/r/v/");

  const appLoaded = () => {
    return !(!appInitialized || loadingAuth || loadingUser || isAuthenticated === null || initialSyncCheckLoading || continuousSyncCheckLoading || loadingDeviceIdentifier);
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const platform = isWeb() ? 'web' : isNative() ? 'native' : 'unknown';

  // Apollo Query for fetching user data
  const [fetchUser, { loading: loadingUser }] = useGetUserLazyQuery({
    fetchPolicy: 'network-only',
    context: { skip401Redirect: true },
    onCompleted: async (data) => {
      if (data?.getUser) {
        setUser(data.getUser);
        setIsAuthenticated(!!data?.getUser);
      }
    },
    onError: async (err) => {
      console.error("Error fetching user:", err.message);
      setUser(null);
      setIsAuthenticated(false);
    },
  });

  const [fetchSyncStatus, { data: initialSyncCheckData, loading: initialSyncCheckLoading, error: initialSyncCheckError }] = useGetSyncStatusLazyQuery({
    variables: { syncType: SyncType.Initial },
  });

  const [fetchContinuousSync, { data: continuousSyncCheckData, loading: continuousSyncCheckLoading, error: continuousSyncCheckError }] = useGetSyncStatusLazyQuery({
    variables: { syncType: SyncType.Continuous },
  });

  useEffect(() => {
    if (user) {
      fetchSyncStatus({ variables: { syncType: SyncType.Initial } });
      fetchContinuousSync({ variables: { syncType: SyncType.Continuous } });
    }
  }, [user]); // ✅ Runs whenever `user` updates


  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;
      if (e.key === "Enter" && target.tagName === "INPUT") {
        target.blur(); // Unfocus the input
        if (isNative()) {
          Keyboard.hide(); // Explicitly close the keyboard
        }
      }
    };

    // Add event listener to the document
    document.addEventListener("keydown", handleKeyPress);

    const fetchDeviceIdentifier = async () => {
      if (!isNative()) {
        setDeviceIdentifier(undefined); // Skip fetching on web
        setLoadingDeviceIdentifier(false);
        return;
      }

      try {
        const { status, deviceIdentifier } = await CalendarPlugin.getCalendarPermissionStatus();
        setDeviceIdentifier(deviceIdentifier);
      } catch (error) {
        console.error("Failed to fetch device identifier:", error);
        setDeviceIdentifier(undefined); // Ensure it's explicitly set to avoid infinite loading
      } finally {
        setLoadingDeviceIdentifier(false); // Mark loading as complete
      }
    };

    fetchDeviceIdentifier();

    // Clean up on unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    const error = searchParams.get('error');
    const message = searchParams.get('message');

    if (error && message) {
      toast.dismiss();
      toast.error(decodeURIComponent(message), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true, // Removes the timer/progress bar
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (message) {
      toast.dismiss();
      toast.success(decodeURIComponent(message), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true, // Removes the timer/progress bar
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (error || message) {
      setSearchParams({});
    }
  }, [searchParams]);

  useEffect(() => {
    const handleReloadUser = async () => {
      console.log('Reloading user...');
      await fetchUser();
    };

    appEvents.on(AppEventType.RELOAD_USER, handleReloadUser);

    // Cleanup listener on unmount
    return () => {
      appEvents.off(AppEventType.RELOAD_USER, handleReloadUser);
    };
  }, [fetchUser]);

  // Handle OAuth redirects
  useEffect(() => {
    let appUrlOpenListener: Promise<PluginListenerHandle>;
    let appStateChangeListener: Promise<PluginListenerHandle>;
    let browserFinishedListener: Promise<PluginListenerHandle>;
    const setupListeners = async () => {
      appUrlOpenListener = CapacitorApp.addListener('appUrlOpen', async (data: { url: string }) => {
        appEvents.emit(AppEventType.RESET_ACTION_BUTTON);
        try {
          const url = new URL(data.url);
          console.log('🔗 Opened via Universal Link:', data.url);

          if (url.pathname.startsWith('/auth/')) {
            console.log('🔑 Navigating to Auth Page');
            navigate(`${url.pathname}${url.search}`);
          }

          if (data.url.startsWith(IOS_GOOGLE_CONFIG.REDIRECT_URI)) {
            Browser.close();
            setVerifying(true);
            const authCode = url.searchParams.get('code');

            if (!authCode) {
              throw new Error('Failed to receive the auth code');
            }

            const verifier = await fetchVerifier();
            const { accessToken, refreshToken, idToken, expiresIn } = await exchangeCodeForTokens(authCode, verifier);

            const isCalendarSync = data.url.includes('calendar');
            const authType = isCalendarSync ? AUTH_TYPE.CALENDAR : AUTH_TYPE.LOGIN;
            const authEmail = await exchangeGoogleTokensForAccesTokens(authType, idToken, accessToken, refreshToken);
            // No need to store the google tokens, comment this out
            // await storeGoogleTokens(accessToken, expiresIn, refreshToken, idToken);

            if (isCalendarSync) {
              console.log('Calendar syncing successful');
              appEvents.emit(AppEventType.CALENDAR_UPDATED, {
                source: CalendarSourceType.GOOGLE,
                account: authEmail,
              });
              return;
            }

            setIsAuthenticated(true);
            await fetchUser();
            setVerifying(false);
            navigate('/');
          }
        } catch (error) {
          console.error("Error during login:", error);
          setVerifying(false);
          navigate('/?error=Login+failed');
        } finally {
          setVerifying(false);
        }
      })

      appStateChangeListener = Browser.addListener('browserFinished', () => {
        appEvents.emit(AppEventType.RESET_ACTION_BUTTON);
      });

      // Detect when app is foregrounded (for extra safety)
      browserFinishedListener = CapacitorApp.addListener('appStateChange', (state) => {
        if (state.isActive) {
          appEvents.emit(AppEventType.RESET_ACTION_BUTTON);
        }
      });
    };

    setupListeners();

    return () => {
      if (appUrlOpenListener) appUrlOpenListener.then((l) => l.remove());
      if (browserFinishedListener) browserFinishedListener.then((l) => l.remove());
      if (appStateChangeListener) appStateChangeListener.then((l) => l.remove());
    };

  }, [navigate, fetchUser]);

  useEffect(() => {
    const initializeApp = async () => {
      console.log("Initializing app...");

      // Skip authentication if LOCAL_MODE is enabled
      if (LOCAL_MODE) {
        await fetchUser();
        setLoadingAuth(false);
        setIsIntroductionDone(true);
        setAppInitialized(true);
        return;
      }

      try {
        let authenticated = false;

        // Perform authentication check
        if (isNative()) {
          authenticated = await checkAuthentication();
        }

        if (authenticated || isMobileWeb()) {
          console.log("User is authenticated or on mobile web, fetching user...");
          await fetchUser();
        } else {
          console.log("User not authenticated.");
          setIsAuthenticated(false);
        }

        // App Introduction Check
        if (!isWeb()) {
          const introComplete = await isAppIntroductionComplete();
          setIsIntroductionDone(introComplete);
        } else {
          setIsIntroductionDone(true);
        }

        // Mark app as initialized
        setAppInitialized(true);

        // Hide splash screen on native platforms
        if (platform !== 'web') {
          CapacitorSplashScreen.hide();
        }

      } catch (error) {
        console.error("Error during app initialization:", error);
      } finally {
        setLoadingAuth(false); // Ensure loading state ends
      }
    };

    initializeApp();
    // Dependencies: Only include stable external functions or constants
  }, [fetchUser, platform]);

  const completeIntroduction = async () => {
    await setAppIntroductionComplete();
    setIsIntroductionDone(true);
  };

  // Show splash screen during loading
  if (!isCaretakerRequestRoute && (SHOW_SPLASH_SCREEN || !appLoaded())) {
    if (isWeb()) {
      return <></>;
    }
    return <SplashScreen />;
  }

  return (
    <SyncStatusProvider identifier={deviceIdentifier}>
      <input
        type="text"
        id="hiddenInput"
        style={{ position: "absolute", top: "-9999px" }}
      />
      <ToastContainer />
      <SyncHandler />
      <Routes>
        <Route path="/auth/login" element={<Login loadingUser={loadingUser || loadingAuth || verifying} />} />
        <Route path="/auth/action" element={<ActionRedirectScreen />} />
        <Route path="/auth/login/existing" element={<ExistingLoginScreen loadUser={async () => { await fetchUser(); }} />} />
        <Route path="/signup" element={<SignUpScreen />} />
        <Route path="/verify-email" element={<VerifyEmailScreen loadUser={async () => { await fetchUser(); }} />} />
        <Route path="/auth/email-verified" element={<EmailVerified />} />
        <Route path="/auth/forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="/auth/reset-password" element={<ResetPasswordScreen />} />
        <Route path="/auth/check-inbox" element={<CheckInboxScreen />} />
        <Route
          path="/"
          element={
            <ProtectedRoute
              isAuthed={isAuthenticated}
              user={user}
              isWeb={platform == 'web'}
              needsIntro={!isIntroductionDone}
              completeIntroductionFunc={completeIntroduction}
              isCalendarBlocked={isBlocked}
              fetchedConnections={fetchedConnections}
              calendarBlockRemove={async () => { await refetchCalendar(); navigate("/"); }}
              needInitialSync={initialSyncCheckData?.getSyncStatus?.status !== TaskStatusType.Completed}
              onCompleteIntialDataLoad={() => navigate("/digest?refetch")}
            >
              {user && <DigestView user={user} />}
              {/* {user && <HomePage user={user} />} */}
            </ProtectedRoute>
          }
        />
        <Route path="/user/profile" element={user ? <ProfileView user={user} onBack={() => navigate("/")} /> : <></>} />
        <Route path="/landing-page" element={<LandingPage />} />
        <Route
          path="/digest"
          element={
            user ? (
              <DigestView user={user} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route path="/r/v/:access_token" element={<CaretakerRequestRecipientPage />} />
        <Route path="/join-beta" element={platform === 'web' ? <JoinBeta /> : <Navigate to="/" replace />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </SyncStatusProvider>
  );
}

export default App;
