import React, { useState } from "react";
import BaseView from "./BaseView"; // Assuming BaseView is implemented
import styles from "./CaretakerRequestCreateView.module.css";
import { Event as DigestEventType, DigestItem as DigestItemType, User, CaretakerDetails } from "../../gql/types";
import TimeRow from "../time/TimeRow";
import { generateTimeOptions } from "../time/TimeOptions";
import { TimeSelectionRow } from "../time/types";
import DigestHeader from "../digest/DigestHeader";
import MainEventItem from "../digest/MainEventItem";
import CaretakerDropdown from "../input/CaretakerDropdown";
import { convertCaretakerDetailsToCaretakers } from "./convertDetailsToCaretakers";
import { CaretakerRequestDetails } from "./types";

type CaretakerRequestCreateViewProps = {
    user: User,
    digestItem: DigestItemType;
    event: DigestEventType; // Event details
    caretakers: CaretakerDetails[]; // List of caretakers to select from
    onBack: () => void; // Back button handler
    onSubmit: (details: CaretakerRequestDetails) => void; // Submit handler
    caretakerRequestDetails?: CaretakerRequestDetails; // for any pre-filled in values
};

const CaretakerRequestCreateView: React.FC<CaretakerRequestCreateViewProps> = ({
    user,
    digestItem,
    event,
    caretakers,
    onBack,
    onSubmit,
    caretakerRequestDetails,
}) => {
    caretakers = caretakers.filter((detail) => detail.userID && detail.userSummary);

    const caretakersWithExternal = [
        ...caretakers,
        {
            userID: "external",
            name: "External User",
            contactInfo: "",
        } as CaretakerDetails,
    ];

    // TODO: Actually extract from the event startDate
    const extractedTimezone = React.useMemo(() => {
        try {
            const intlOptions = { timeZoneName: "short" };
            const formatter = new Intl.DateTimeFormat("en-US", intlOptions as any);
            return formatter.resolvedOptions().timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch {
            return Intl.DateTimeFormat().resolvedOptions().timeZone; // Fallback to local timezone
        }
    }, [event.startDate]);

    const [selectedCaretaker, setSelectedCaretaker] = useState<CaretakerDetails | null>(
        (caretakerRequestDetails && caretakerRequestDetails.selectedCaretaker) || caretakers[0] || null
    );
    const [startDate, setStartDate] = useState(caretakerRequestDetails && caretakerRequestDetails.startDate || event.startDate);
    const [endDate, setEndDate] = useState(caretakerRequestDetails && caretakerRequestDetails.endDate || event.endDate);
    const [message, setMessage] = useState(caretakerRequestDetails && caretakerRequestDetails.message || "");
    const convertedCaretakers = convertCaretakerDetailsToCaretakers(caretakersWithExternal, user.id, user.spouse?.id);

    const handleSubmit = () => {
        if (selectedCaretaker && startDate && endDate) {
            onSubmit({
                selectedCaretaker,
                startDate,
                endDate,
                timezone: extractedTimezone,
                message,
            });
        }
    };

    // Handler for updating the selected caretaker
    const handleSelectCaretaker = (caretakerId: string) => {
        const selected = caretakersWithExternal.find((caretaker) => caretaker.userID === caretakerId);
        if (selected) {
            setSelectedCaretaker(selected);
        }
    };

    // Handler for TimeRow changes
    const handleTimeRowChange = (
        id: number,
        field: keyof TimeSelectionRow,
        value: string
    ) => {
        const applyTimezone = (time: string, timezone: string): string => {
            // Extract the date part from event.startDate
            const datePart = event.startDate.split("T")[0]; // "2025-01-19"

            // Create a Date object in the provided timezone
            const localDate = new Date(`${datePart}T${time}`);

            // Use Intl.DateTimeFormat to ensure proper alignment with the timezone
            const formatter = new Intl.DateTimeFormat("en-US", {
                timeZone: timezone,
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            });

            // Format the date in the target timezone
            const parts = formatter.formatToParts(localDate);
            const formattedDate = `${parts.find(p => p.type === "year")?.value}-${parts.find(p => p.type === "month")?.value}-${parts.find(p => p.type === "day")?.value}`;
            const formattedTime = `${parts.find(p => p.type === "hour")?.value}:${parts.find(p => p.type === "minute")?.value}`;

            // Combine the date and time to match the required format
            const result = `${formattedDate}T${formattedTime}`;
            return result;
        };

        if (field === "startTime") {
            console.log("changing start time");
            setStartDate(applyTimezone(value, extractedTimezone)); // Store timezone-adjusted start time
        } else if (field === "endTime") {
            setEndDate(applyTimezone(value, extractedTimezone)); // Store timezone-adjusted end time
        }
    };

    return (
        <BaseView
            title="Caretaker Request"
            onBack={onBack}
            footer={
                <button
                    className={styles.submitButton}
                    onClick={handleSubmit}
                    disabled={!selectedCaretaker || !startDate || !endDate}
                >
                    Create and Share Request
                </button>
            }
        >
            <div className={styles.requestViewContainer}>
                <div className={styles.infoSection}>
                    Please edit and confirm the details below to create your request and share.
                    <br /><br />
                    For a non-Plii user, please select <div className={styles.infoBold}>External User</ div> as the Caretaker option.
                </div>

                <div className={styles.requestViewHeader}>
                    <DigestHeader
                        topTitle={digestItem.itemHeader?.topTitle ?? ""}
                        mainTitle={digestItem.itemHeader?.mainTitle ?? ""}
                        attentionLevel={digestItem.attentionLevel}
                        description={digestItem.itemHeader?.description ?? undefined}
                    />

                    <MainEventItem
                        event={event}
                        isEventInteractable={false}
                    />
                </div>

                <div className={styles.sectionHeader}>Start & End Time:</div>

                <div className={styles.timeRowContainer}>
                    {/* Form Content */}
                    <TimeRow
                        timeOptions={generateTimeOptions()}
                        startTime={startDate}
                        endTime={endDate ?? ''}
                        id={1}
                        date={event.startDate}
                        onRowChange={handleTimeRowChange}
                        enableDateSelection={false}
                        timezone={extractedTimezone}
                    />
                </div>

                {/* Caretaker Selection */}
                <div className={styles.sectionHeader}>Caretaker:</div>
                <div className={styles.caretakerDropdownContainer}>
                    <CaretakerDropdown
                        caretakers={convertedCaretakers}
                        selectedCaretakerId={selectedCaretaker && selectedCaretaker.userID ? selectedCaretaker.userID : ""}
                        onSelectCaretaker={handleSelectCaretaker}
                    />
                </div>
                {/* Notes/Message */}
                <div className={styles.sectionHeader}>Notes/Message:</div>
                <textarea
                    className={styles.messageInput}
                    placeholder="Add a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>
        </BaseView >
    );
};

export default CaretakerRequestCreateView;
